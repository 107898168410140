<template>
    <div>
        <h1 class="title is-4">
            {{ $t("Interface.Contract.Details.Title") }}
        </h1>
        <h2 v-if="CompanyCreated" class="subtitle is-6">
            <template v-if="!Accepted">
                {{ $t("Interface.Contract.Details.NotAcceptedText") }}
            </template>
            <template v-else>
                {{ $t("Interface.Contract.Details.AcceptedText") }}
            </template>
        </h2>
        <h2 v-else class="subtitle is-6">{{ $t("Interface.Contract.Details.NotCreatedText") }}</h2>
        <div class="box">
            <template v-if="CompanyCreated">
                <div class="content">
                    <strong>{{ $t("Interface.Contract.Company.Type") }}:&nbsp;</strong>
                    <span v-if="Type === 'owner'">{{ $t("Interface.Contract.Company.TypeOwner") }}</span>
                    <span v-else>{{ $t("Interface.Contract.Company.TypeAgent") }}</span>
                    <br><br>

                    <strong>{{ $t("Interface.Contract.Company.FullName") }}:&nbsp;</strong>
                    <span>{{ FullName }}</span>
                    <br>

                    <strong v-if="Country === 'ru'">
                        {{ $t("Interface.Contract.Company.OGRN") }}:&nbsp;
                    </strong>
                    <strong v-if="Country === 'us'">
                        {{ $t("Interface.Contract.Company.TinEin") }}:&nbsp;
                    </strong>
                    <span>{{ Ogrn }}</span>
                    <br>

                    <strong v-if="Country === 'ru'">
                        {{ $t("Interface.Contract.Company.KPP") }}:&nbsp;
                    </strong>
                    <strong v-if="Country === 'us'">
                        {{ $t("Interface.Contract.Company.State") }}:&nbsp;
                    </strong>
                    <span>{{ Kpp }}</span>
                    <br>

                    <strong>{{ $t("Interface.Contract.Company.Address") }}:&nbsp;</strong>
                    <span>{{ Address }}</span>
                    <br>

                    <strong>
                        {{ $t("Interface.Contract.Company.ManagerPosition") }}, {{ $t("Interface.Contract.Company.ManagerName") }}:&nbsp;
                    </strong>
                    <span>{{ ManagerPosition }}, {{ ManagerName }}</span>
                    <br><br>

                    <strong>{{ $t("Interface.Contract.Bank.Title") }}:&nbsp;</strong>
                    <span>
                        {{ $t("Interface.Contract.Bank.BIC") }}: {{ Bic }},
                        {{ $t("Interface.Contract.Bank.RS") }}: {{ BankAccount }}
                    </span>
                </div>
                <a @click="toContract()">
                    {{ $t("Interface.Contract.Details.ViewContractButton") }}
                </a>
                <br>
                <b-button
                    v-if="Country === 'ru' && !Accepted"
                    type="button"
                    class="button is-theme"
                    :loading="IsButtonLoading"
                    @click.prevent="makeYandexPay()"
                >
                    {{ $t("Interface.Contract.Details.AcceptButton") }}
                </b-button>
                <b-button
                    v-else-if="Country === 'us' && !Accepted"
                    type="button"
                    class="button is-theme"
                    :loading="IsButtonLoading"
                    @click.prevent="makePaypalPay()"
                >
                    {{ $t("Interface.Contract.Details.AcceptButton") }}
                </b-button>
            </template>
            <template v-else>
                <div class="columns is-multiline">
                    <div class="column is-half">
                        <b-field :label="$t('Interface.Contract.Details.Country')">
                            <b-select
                                v-model="SelectedCountry"
                                :placeholder="$t('Interface.Contract.Details.CountryPlaceholder')"
                                expanded
                                @input="IsContactFormActive = !IsSelectedCountryAllowed"
                            >
                                <option
                                    v-for="Country in CountryList"
                                    :key="Country.code"
                                    :value="Country.code"
                                >
                                    {{ Country.name }}
                                </option>
                            </b-select>
                        </b-field>
                        <button
                            type="submit"
                            class="button is-theme"
                            :disabled="!IsSelectedCountryAllowed"
                            @click.prevent="toCreateForm()"
                        >
                            {{ $t("Interface.Contract.Details.CreateButton") }}
                        </button>
                    </div>
                </div>
                <b-modal
                    :active.sync="IsContactFormActive"
                    trap-focus
                    aria-role="dialog"
                    aria-modal
                >
                    <ContactForm :selected-country="SelectedCountry" />
                </b-modal>
            </template>
            <b-loading :is-full-page="true" :active.sync="IsLoading" :can-cancel="false" />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex"
import ContactForm from "@/components/Widget/ContactForm.vue"
import LoadingState from "@/mixins/LoadingState"
import Helpers from "@/utils/Helpers"
import { COMPANY_INFO_REQUEST, CONTRACT_INFO_REQUEST } from "@/store/actions/contract"
import { PAYMENT_YANDEX_TOKEN_REQUEST } from "@/store/actions/payment"

export default {
    name: "ContractDetails",
    components: {
        ContactForm
    },
    mixins: [LoadingState],
    data() {
        return {
            SelectedCountry: null,
            IsContactFormActive: false,
            IsButtonLoading: false
        }
    },
    computed: {
        CompanyCreated() {
            return this.$store.getters.IS_COMPANY_CREATED
        },
        CountryList() {
            return this.$store.state.CountryDB.List
        },
        AllowedCountryList() {
            return this.$store.state.CountryDB.AllowedList
        },
        IsSelectedCountryAllowed() {
            return this.AllowedCountryList.find((x) => x === this.SelectedCountry)
        },
        ...mapState({
            Country: (state) => state.Contract.Company.Country,
            FullName: (state) => state.Contract.Company.FullName,
            ShortName: (state) => state.Contract.Company.ShortName,
            Registered: (state) => new Date(state.Contract.Company.Registered).toLocaleDateString(),
            Ogrn: (state) => state.Contract.Company.Ogrn,
            Inn: (state) => state.Contract.Company.Inn,
            Kpp: (state) => state.Contract.Company.Kpp,
            Address: (state) => state.Contract.Company.Address,
            ManagerPosition: (state) => state.Contract.Company.ManagerPosition,
            ManagerName: (state) => state.Contract.Company.ManagerName,
            ManagerCredentials: (state) => state.Contract.Company.ManagerCredentials,
            BankName: (state) => state.Contract.Company.BankName,
            BankCity: (state) => state.Contract.Company.BankCity,
            Bic: (state) => state.Contract.Company.Bic,
            CorrespondentBankAccount: (state) => state.Contract.Company.CorrespondentBankAccount,
            BankAccount: (state) => state.Contract.Company.BankAccount,
            Accepted: (state) => state.Contract.Accepted,
            Type: (state) => state.Contract.Company.Type
        })
    },
    mounted() {
        if (!this.$store.getters.IS_COMPANY_CREATED) {
            this.switchLoading()
            this.$store.dispatch(COMPANY_INFO_REQUEST)
                .then(() => {
                    if (!this.$store.getters.IS_CONTRACT_ACCEPTED && this.$store.getters.IS_COMPANY_CREATED) {
                        this.switchLoading()
                        this.$store.dispatch(CONTRACT_INFO_REQUEST)
                            .then(() => {})
                            .finally(() => this.switchLoading())
                    }
                })
                .finally(() => this.switchLoading())
        }
        const CurrentLanguageCounrty = this.$store.getters.GET_LANGUAGE_BY_SHORTCODE(Helpers.getCurrentLanguage())
        if (CurrentLanguageCounrty.code === "ru-RU" || CurrentLanguageCounrty.code === "en-US") {
            this.SelectedCountry = CurrentLanguageCounrty.code.substr(3, 2).toLowerCase()
        }
    },
    methods: {
        toCreateForm() {
            this.$router.push({ name: `Contract-Create-${this.SelectedCountry}` })
        },
        toContract() {
            const RouteData = this.$router.resolve({ name: `Contract-File-${this.Country}-${this.Type}` })
            window.open(RouteData.href, "_blank")
        },
        makeYandexPay() {
            if (this.Country === "ru") {
                this.IsButtonLoading = true
                this.$store.dispatch(PAYMENT_YANDEX_TOKEN_REQUEST, `${window.location.protocol}//${window.location.host}${this.$router.resolve({ name: "Payment-Yandex-Success" }).href}`)
                    .then(() => this.$router.push({ name: "Payment-Yandex-Widget" }))
                    .finally(() => {
                        this.IsButtonLoading = false
                    })
            }
        },
        makePaypalPay() {
            if (this.Country === "us") {
                Helpers.notify("is-info", "Payment is not implemented yet")
            }
        }
    },
    metaInfo() {
        return {
            title: this.$t("Interface.Contract.Details.Title")
        }
    }
}
</script>
