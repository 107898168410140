<template>
    <div class="form-inner">
        <template v-if="!Sent">
            <h1 class="title is-3 has-text-centered">{{ $t("Interface.ContactForm.Title") }}</h1>
            <transition name="fade" mode="out-in">
                <ValidationObserver
                    v-slot="{ handleSubmit, invalid }"
                    tag="form"
                    class="gotoform form box show-on-scroll"
                >
                    <p>
                        {{ $t("Interface.ContactForm.Subtitle") }}
                    </p>
                    <ValidationProvider v-slot="{ valid, errors }" tag="div" rules="required|min:2" class="form-field field">
                        <div class="floating-control control has-icons-right">
                            <input
                                v-model="Form.Name"
                                class="input"
                                :class="{ 'is-danger': errors[0] }"
                                :name="$t('Interface.ContactForm.Name')"
                                type="text"
                                data-cy="Name"
                                required
                                @input="Form.Name = $event.target.value"
                            >
                            <label class="label">
                                {{ $t("Interface.ContactForm.Name") }}
                            </label>
                            <span v-if="valid" class="goto-icon icon is-right">
                                <i class="goto-icon-check" />
                            </span>
                        </div>
                        <p v-if="errors[0]" class="help is-danger">
                            <span class="goto-icon icon is-small is-right">
                                <i class="goto-icon-error" />
                            </span>
                            {{ errors[0] }}
                        </p>
                    </ValidationProvider>

                    <ValidationProvider v-slot="{ valid, errors }" tag="div" rules="required|email" class="form-field field">
                        <div class="floating-control control has-icons-right">
                            <input
                                v-model="Form.Email"
                                class="input"
                                :class="{ 'is-danger': errors[0] }"
                                :name="$t('Interface.ContactForm.Email')"
                                type="text"
                                data-cy="Email"
                                :disabled="Form.IsEmailConfirmed === true"
                                required
                            >
                            <label class="label">
                                {{ $t("Interface.ContactForm.Email") }}
                            </label>
                            <span v-if="valid" class="goto-icon icon is-right">
                                <i class="goto-icon-check" />
                            </span>
                        </div>
                        <p v-if="errors[0]" class="help is-danger">
                            <span class="goto-icon icon is-small is-right">
                                <i class="goto-icon-error" />
                            </span>
                            {{ errors[0] }}
                        </p>
                    </ValidationProvider>

                    <ValidationProvider
                        v-slot="{ valid, errors }"
                        tag="div"
                        rules="required|phone"
                        class="form-field field"
                        :name="$t('Interface.ContactForm.Phone')"
                    >
                        <div class="floating-control control has-icons-right">
                            <cleave
                                v-model="Form.Phone"
                                class="input"
                                :class="{ 'is-danger': errors[0] }"
                                :options="CustomPhone"
                                type="text"
                                data-cy="Phone"
                                :disabled="Form.IsPhoneVerified === true"
                                required
                            />
                            <label class="label">
                                {{ $t("Interface.ContactForm.Phone") }}
                            </label>
                            <span v-if="valid" class="goto-icon icon is-right">
                                <i class="goto-icon-check" />
                            </span>
                        </div>
                        <p v-if="errors[0]" class="help is-danger">
                            <span class="goto-icon icon is-small is-right">
                                <i class="goto-icon-error" />
                            </span>
                            {{ errors[0] }}
                        </p>
                    </ValidationProvider>

                    <ValidationProvider v-slot="{ valid, errors }" tag="div" rules="required|min:5" class="form-field field">
                        <div class="floating-control control has-icons-right">
                            <input
                                v-model="Form.Website"
                                class="input"
                                :class="{ 'is-danger': errors[0] }"
                                :name="$t('Interface.ContactForm.Site')"
                                type="text"
                                data-cy="Site"
                                required
                            >
                            <label class="label">
                                {{ $t("Interface.ContactForm.Site") }}
                            </label>
                            <span v-if="valid" class="goto-icon icon is-right">
                                <i class="goto-icon-check" />
                            </span>
                        </div>
                        <p v-if="errors[0]" class="help is-danger">
                            <span class="goto-icon icon is-small is-right">
                                <i class="goto-icon-error" />
                            </span>
                            {{ errors[0] }}
                        </p>
                    </ValidationProvider>
                    <br>
                    <button
                        type="submit"
                        class="button is-theme is-fullwidth"
                        :disabled="invalid"
                        @click.prevent="handleSubmit(submitForm)"
                    >
                        {{ $t("Interface.Button.Submit") }}
                    </button>
                    <b-loading :is-full-page="true" :active.sync="IsLoading" :can-cancel="false" />
                </ValidationObserver>
            </transition>
        </template>
        <template v-if="Sent">
            <transition name="fade" mode="out-in">
                <div class="successform has-text-centered">
                    <img src="@/assets/images/success.svg">
                    <h5 class="gototitle title">{{ $t("Interface.ContactForm.Success.Title") }}</h5>
                    <p class="gototext" v-html="$t('Interface.ContactForm.Success.Text')" />
                </div>
            </transition>
        </template>
    </div>
</template>

<script>
import Cleave from "vue-cleave-component"
// eslint-disable-next-line no-unused-vars
import CleavePhone from "cleave.js/dist/addons/cleave-phone.i18n"
import LoadingState from "@/mixins/LoadingState"
import Helpers from "@/utils/Helpers"

export default {
    components: {
        Cleave
    },
    mixins: [LoadingState],
    props: {
        selectedCountry: {
            default: "",
            type: String
        }
    },
    data() {
        return {
            Form: {
                Name: this.$store.state.Account.Name,
                Email: this.$store.state.Account.Email,
                Phone: `+${this.$store.state.Account.Phone}`,
                Website: ""
            },
            Sent: false,
            CustomPhone: {
                phone: true,
                prefix: "+",
                numericOnly: true,
                noImmediatePrefix: true
            }
        }
    },
    computed: {
        queryParams() {
            const UrlQuery = this.$route.fullPath
            if (UrlQuery && !(UrlQuery === "/" || UrlQuery === "/ru")) { return `URL params: ${UrlQuery}` }
            return null
        }
    },
    created() {
        this.loadSMTPScript()
    },
    methods: {
        loadSMTPScript() {
            const script = document.createElement("script")
            script.src = "https://smtpjs.com/v3/smtp.js"
            script.type = "text/javascript"
            document.getElementsByTagName("head")[0].appendChild(script)
        },
        submitForm() {
            this.switchLoading()
            /* eslint-disable-next-line no-undef */
            Email.send({
                Host: "smtp.sendgrid.net",
                Username: "apikey",
                Password: process.env.VUE_APP_SENDGRID_APIKEY,
                Port: "587",
                To: "at@goto.llc",
                From: "at@goto.llc",
                Subject: this.$t("Interface.ContactForm.Subject"),
                Body: `
                    ${this.$t("Interface.ContactForm.Name")}: ${this.Form.Name}<br/>
                    ${this.$t("Interface.ContactForm.Email")}: ${this.Form.Email}<br/>
                    ${this.$t("Interface.ContactForm.Phone")}: ${this.Form.Phone}<br/>
                    ${this.$t("Interface.ContactForm.Site")}: ${this.Form.Website}<br/>
                    ${this.$t("Interface.Contract.Details.Country")}:
                    ${this.$store.getters.GET_CONTRY_BY_CODE(this.selectedCountry).name}<br/>
                    ${(this.queryParams) ? this.queryParams : ""}
                `
            })
                .then((message) => {
                    if (message === "OK") { this.Sent = !this.Sent }
                    else Helpers.notify("is-danger", message)
                    this.switchLoading()
                })
        }
    }
}
</script>
